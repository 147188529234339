import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

const CodesTracker = ({ vodafone, activation }) => {
  const [refetch, setRefetch] = useState(false);
  const [submitGenerate, setSubmitGenerate] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const api = activation
    ? process.env.REACT_APP_ADMIN_ACTIVATION_CODE_TRACKER_API
    : process.env.REACT_APP_ADMIN_CODE_TRACKER_API;
  const [trackCodesSuccess, trackCodesErrors, submitLoading] = useAxios(
    api,
    "POST",
    submitGenerate.flag,
    submitGenerate.dependency,
    submitGenerate.data,
    true
  );
  const onSubmit = (data) => {
    let finalData;

    if (vodafone || activation) {
      finalData = { code: data.code_id };
    } else {
      finalData = { code_id: data.code_id };
    }
    setSubmitGenerate({
      flag: "generateCodesRequest",
      dependency: !submitGenerate.dependency,
      data: { ...finalData, type: vodafone ? "vodafone" : "codes" },
    });
  };

  useEffect(() => {
    //! reset the form on success submit

    if (trackCodesSuccess) {
      reset();
      setRefetch(!refetch);
    }
  }, [trackCodesSuccess]);

  useEffect(() => {
    reset();
  }, [reset, vodafone]);
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Code Tracking | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          {vodafone && "Vodafone "}
          {activation && "Activation "} Code tracking
        </h2>

        <div className="w-full form-codes-container">
          <h2 className="my-20 text-center text-3xl font-semibold">
            Please fill in the information to track the code
          </h2>
          {trackCodesErrors && (
            <p className={`text-center font-bold mb-6 text-accent`}>
              {trackCodesErrors}
            </p>
          )}
          {trackCodesSuccess?.message && (
            <p className={`text-center font-bold mb-6 text-secondary `}>
              {trackCodesSuccess?.message}
            </p>
          )}
          <div className="form-codes flex w-full  items-start justify-center gap-20 lg:flex-col lg:items-center lg:justify-start ">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-fit w-[380px] flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 "
            >
              <div className="w-full">
                {/** seryal code field */}
                <div className=" flex w-full flex-col items-end justify-center gap-2 md:w-full">
                  <label className="w-full truncate" htmlFor="code_id">
                    Code
                  </label>
                  <input
                    className="signin-inputs signin-input w-full "
                    type="text"
                    id="code_id"
                    placeholder="000"
                    name="code_id"
                    autoComplete="on"
                    {...register("code_id", {
                      required: true,
                      min: 1,
                    })}
                  />

                  {errors.code_id && (
                    <p className="text-xl text-blue-500 ">
                      {errors.code_id.type === "required" &&
                        "This field is required."}
                      {errors.code_id.type === "max" &&
                        "Max letters count is 255 letter"}
                      {errors.code_id.type === "min" &&
                        "Please enter at least one letter"}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    trackCodesErrors &&
                      trackCodesErrors?.response?.data?.errors?.code_id && (
                        <p className="w-full text-end text-xl text-blue-500  ">
                          {trackCodesErrors?.response?.data?.errors?.code_id[0]}
                        </p>
                      )
                  }
                </div>
              </div>
              {/** submit */}
              <button
                disabled={!isValid || (submitLoading && submitGenerate.flag)}
                className=" submit mt-6 w-full "
                type="submit"
              >
                {submitLoading && submitGenerate.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Tracking"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CodesTracker;
