import { Link, useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import { useNetworkState } from "react-use";
import { message } from "antd";
import toast from "react-hot-toast";
import moment from "moment/moment.js";

function CardIdForm({
  studentData,
  setStudentData,
  handleAddStudentWhenOffline,
}) {
  const { lectureID, groupID } = useParams();
  const idCard = useRef(null);
  const [errors, setErrors] = useState("");
  const state = useNetworkState();

  const [attendByCardID, setAttendByCardID] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [attendByCardIDSuccess, attendByCardIDErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ATTEND_STUDENT_API,
    "POST",
    attendByCardID.flag,
    attendByCardID.dependency,
    attendByCardID.data,
    true
  );
  useEffect(() => {
    idCard?.current?.focus();
    if (attendByCardIDSuccess) {
      setErrors("");
      idCard.current.value = "";
      setStudentData(attendByCardIDSuccess.data);
    }
    if (attendByCardIDErrors) {
      setErrors("");
    }
  }, [
    attendByCardIDSuccess,
    idCard,
    studentData,
    attendByCardIDErrors,
    setStudentData,
  ]);

  const reset = () => {
    idCard.current.value = "";
  };

  function onSubmit(e) {
    e.preventDefault();

    if (idCard?.current?.value === "") {
      setErrors("Please fill out this field");
    } else {
      if (state.online) {
        setAttendByCardID({
          flag: "attendByCardID",
          dependency: !attendByCardID.dependency,
          data: {
            value: idCard?.current?.value,
            type: "magnetic_id",
            group_id: groupID,
            lecture_id: lectureID,
          },
        });
      } else {
        handleAddStudentWhenOffline(idCard?.current?.value, reset);
      }
    }
  }
  return (
    <form
      onSubmit={onSubmit}
      method="post"
      className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl    p-16 shadow-lg shadow-blue-200/50 sm:w-3/4"
    >
      <div className=" flex w-full flex-col items-end justify-center gap-2">
        <label htmlFor="id_card">Card number</label>
        <input
          ref={idCard}
          className="signin-inputs signin-input max-w-60 "
          type="text"
          id="id_card"
          placeholder="Card number"
          name="id_card"
          autoComplete="on"
        />
        <p className="text-[12px] text-blue-900 ">{errors}</p>
        {
          //!-------server errors -----

          attendByCardIDErrors &&
            attendByCardIDErrors?.response?.data?.message && (
              <p className="w-full  text-[12px] text-blue-900  ">
                {attendByCardIDErrors?.response?.data?.message}
              </p>
            )
        }
      </div>

      <button className=" submit max-w-60 mt-10 " type="submit">
        {attendByCardID.flag && submitLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Attend"
        )}
      </button>
    </form>
  );
}
function MobileForm({
  studentData,
  setStudentData,
  handleAddStudentWhenOffline,
}) {
  const { lectureID, groupID } = useParams();
  const state = useNetworkState();
  console.log(state);

  const [attendByMobile, setAttendByMobile] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [attendByMobileSuccess, attendByMobileErrors, submitLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ATTEND_STUDENT_API,
    "POST",
    attendByMobile.flag,
    attendByMobile.dependency,
    attendByMobile.data,
    true
  );

  useEffect(() => {
    if (attendByMobileSuccess) {
      setStudentData(attendByMobileSuccess?.data);
    }
  }, [attendByMobileSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });
  function onSubmit(data) {
    if (state.online) {
      setAttendByMobile({
        flag: "attendByMobile",
        dependency: !attendByMobile.dependency,
        data: {
          ...data,
          type: "mobile",
          group_id: groupID,
          lecture_id: lectureID,
        },
      });
    } else {
      handleAddStudentWhenOffline(data.value, reset);
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      method="post"
      className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl    p-16 shadow-lg shadow-blue-200/50 sm:w-3/4"
    >
      <div className=" flex w-full flex-col items-end justify-center gap-2">
        <label htmlFor="value">Phone number</label>
        <input
          placeholder="Phone number"
          className="signin-inputs signin-input w-full "
          type="text"
          id="value"
          name="value"
          autoComplete="on"
          {...register("value", {
            required: true,
            pattern: /^[\d]{11}/,
            maxLength: 11,
          })}
        />
        {errors.value && (
          <p className="text-[12px] text-blue-900 ">
            {errors.value.type === "required" && "Please enter phone number"}
            {errors.value.type === "pattern" &&
              "This phone number is incorrect"}
            {errors.value.type === "maxLength" &&
              "This phone number is incorrect"}
          </p>
        )}
        {
          //!-------server errors -----

          attendByMobileErrors &&
            attendByMobileErrors?.response?.data?.message && (
              <p className="w-full  text-[12px] text-blue-900  ">
                {attendByMobileErrors?.response?.data?.message}
              </p>
            )
        }
      </div>

      <button className=" submit max-w-60 mt-10 " type="submit">
        {attendByMobile.flag && submitLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Attend"
        )}
      </button>
    </form>
  );
}

function StudentHistoryTable({ studentHistory }) {
  const table = useTable(
    "studentHistory",
    false,
    "",
    "",
    "",
    "",
    true,
    studentHistory
  );

  return <div className="h-fit  w-full">{table}</div>;
}

function SessionStudents() {
  const AuthState = useAuthState();

  const { lectureID, groupID } = useParams();
  const [studentData, setStudentData] = useState({});
  const [reFetch, setReFetch] = useState(false);
  const [values, setValues] = useState(false);
  const [updateQuizDegree, setUpdateQuizDegree] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [allData, setAllData] = useState([]);
  const [offlineData, setOfflineData] = useState([]);
  const { online } = useNetworkState();

  const [
    getAllStudentsAttendance,
    getAllStudentsAttendanceErrors,
    getAllStudentsAttendanceLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_GET_ALL_STUDENT_IN_THE_SESSIOIN_API}/${groupID}`,
    "GET",
    "GET"
  );

  const [updateQuizDegreeSuccess, updateQuizDegreeErrors, submitLoading] =
    useAxios(
      process.env
        .REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_UPDATE_QUIZ_DEGREE_API,
      "POST",
      updateQuizDegree.flag,
      updateQuizDegree.dependency,
      updateQuizDegree.data,
      true
    );

  // Calculate expire_at for each entry
  const students = offlineData.map((data) => {
    return {
      ...data,
      id: data.key,
      attend_at: moment(data.actionTime).format("YYYY-MM-DD hh:mm:ss"),
    };
  });

  // Use your axios hook or method to post the data
  const [
    attendAllStudentSuccess,
    attendAllStudentErrors,
    attendAllStudentLoading,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_ADD_ALL_STUDENT_IN_THE_SESSIOIN_API,
    "POST",
    "POST",
    online,
    {
      students,
      group_id: groupID,
      lecture_id: lectureID,
    }
  );

  useEffect(() => {
    if (updateQuizDegreeSuccess) {
      setReFetch(!reFetch);
    }
  }, [attendAllStudentSuccess, reFetch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    values,
  });

  const [
    SessionAttendanceData,
    SessionAttendanceErrors,
    SessionAttendanceloading,
  ] = useAxios(
    `${process.env.REACT_APP_ADMIN_SINGLE_SESSION_ATTENDANCE_API}${lectureID}/${groupID}`,
    "GET",
    "GET",
    studentData,
    "",
    "",
    reFetch
  );

  const table = useTable(
    "sessionAttendance",
    false,
    "",
    allData,
    "",
    "",
    true,
    allData,
    reFetch,
    setReFetch
  );

  const handleAddStudentWhenOffline = (value, callBack) => {
    const student = getAllStudentsAttendance?.data
      ?.filter(
        (student) => student.mobile === value || +student.magnetic_id === +value
      )
      .map((data) => ({
        ...data,
        actionTime: data.actionTime ? data.actionTime : moment(), // Capture the current time for each entry
      }));

    console.log(
      getAllStudentsAttendance?.data?.filter(
        (student) => +student.magnetic_id === 123
      )
    );
    if (allData.filter((data) => data.mobile === value).length > 0) {
      toast.error("This Student Alearedy Attended");
    } else if (student.length > 0) {
      setAllData((prev) => [...prev, student[0]]);
      setOfflineData((prev) => [...prev, student[0]]);

      toast.success("Student Pending Now, please connect to the internet");
    } else {
      toast.error("This Student Not Found");
    }
    callBack();
    console.log(
      value,
      allData.filter((data) => data.mobile === value),
      allData.filter((data) => data.mobile === value)
    );
  };

  useEffect(() => {
    if (SessionAttendanceData) {
      setValues({ degree_quiz: SessionAttendanceData?.data[0]?.quiz_degree });
      setAllData(SessionAttendanceData?.data);
    }
  }, [SessionAttendanceData]);

  useEffect(() => {
    if (attendAllStudentSuccess) {
      toast.success("Attend Student successfully");
    }
  }, [attendAllStudentSuccess]);

  function onSubmit(data) {
    setUpdateQuizDegree({
      flag: "updateQuizDegree",
      dependency: !updateQuizDegree.dependency,
      data: {
        ...data,
        group_id: groupID,
        lecture_id: lectureID,
      },
    });
  }

  return (
    <section className="flex h-full w-full flex-col items-center gap-10 overflow-y-auto px-20 py-20 sm:px-10">
      <HelmetTags title="Attendance | Nawar Academy"></HelmetTags>

      <h2 className="mb-20 w-full text-4xl font-bold">
        Student attendance: {allData.length}
      </h2>
      <div className="current-student-info flex items-center justify-center gap-10 md:flex-col">
        {studentData?.last_attendance !== 0 &&
          SessionAttendanceData?.last_attendance && (
            <p>
              {studentData?.last_attendance} :
              <span className="font-semibold">Last attendance</span>
            </p>
          )}
        {studentData?.student_name && (
          <p>
            <span className="font-semibold">Name: </span>
            {studentData?.student_name}
          </p>
        )}
        {offlineData?.slice(-1)[0] && (
          <>
            <p>
              <span className="font-semibold">Name: </span>
              {offlineData?.slice(-1)[0]?.name}{" "}
            </p>
            <p>
              <span className="font-semibold">Mobile: </span>
              {offlineData?.slice(-1)[0]?.mobile}{" "}
            </p>
            <p>
              <span className="font-semibold">Parent Mobile: </span>
              {offlineData?.slice(-1)[0]?.parent_mobile}{" "}
            </p>
            <p>
              <span className="font-semibold">Parent Mobile 2: </span>
              {offlineData?.slice(-1)[0]?.parent_mobile2}{" "}
            </p>
          </>
        )}
      </div>
      {/**
       * //!StudentTable
       *
       */}
      {studentData?.history && studentData?.history?.length > 0 && (
        <StudentHistoryTable studentHistory={studentData?.history} />
      )}

      {/**
       * //!Id Card form
       *
       */}
      <CardIdForm
        studentData={studentData}
        setStudentData={setStudentData}
        handleAddStudentWhenOffline={handleAddStudentWhenOffline}
      />

      {/**
       * ////!mobile form
       *
       */}
      <MobileForm
        studentData={studentData}
        setStudentData={setStudentData}
        handleAddStudentWhenOffline={handleAddStudentWhenOffline}
      />
      <div
        className="all-students-attendance my-7 flex w-full items-center justify-between gap-10
      md:flex-col"
      >
        <Link
          to={`/admin/attendance/${groupID}/${lectureID}/absence`}
          className=" rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
        >
          Absence
        </Link>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_view")
          ) ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("attendance_viewnumberofstudent")
          )) && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="post"
            className="flex flex-row-reverse w-1/2 items-start justify-end  md:w-full md:justify-center"
          >
            <button
              className="submit max-w-60 !rounded-l-none  active:!scale-100"
              type="submit"
            >
              {updateQuizDegree.flag && submitLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Edit"
              )}
            </button>
            <div className="flex w-full flex-col items-end justify-center gap-2">
              <input
                placeholder="Exam degree"
                className="signin-inputs signin-input w-full !rounded-r-none !border-r-0"
                type="number"
                inputMode="numeric"
                id="degree_quiz"
                name="degree_quiz"
                autoComplete="on"
                {...register("degree_quiz", {
                  required: true,
                  pattern: /^[\d]/,
                  maxLength: 11,
                })}
              />
              {errors.degree_quiz && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.degree_quiz.type === "required" &&
                    "Please fill out this field"}
                  {errors.degree_quiz.type === "pattern" &&
                    "Only numbers are allowed"}
                  {errors.degree_quiz.type === "maxLength" &&
                    "Only numbers are allowed"}
                </p>
              )}
              {
                //!-------server errors -----

                updateQuizDegreeErrors &&
                  updateQuizDegreeErrors?.response?.data?.message && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {updateQuizDegreeErrors?.response?.data?.message}
                    </p>
                  )
              }
            </div>
          </form>
        )}
      </div>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default SessionStudents;
