import { BsFillChatRightDotsFill } from "react-icons/bs";
import ChatContent from "./sections/chatContent/ChatContent";
import { useEffect, useReducer, useRef, useState } from "react";
import style from "./styles/studentChat.module.scss";
import ChatFooter from "./sections/chatFooter/ChatFooter";
import Pusher from "pusher-js";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import axios from "axios";

export default function StudentChat() {
  const [open, setOpen] = useState(false);

  const stream = useRef(null);
  const recorder = useRef(null);
  const items = useRef([]);
  const counter = useRef(null);
  const seconds = useRef(null);
  const minutes = useRef(null);
  const textInput = useRef(null);
  const [messages, setMessages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [chatData, chatDataErrors, chatDataLoading] = useAxios(
    process.env.REACT_APP_STUDENT_CHATS_API,
    "GET",
    "GET",
    "",
    ""
  );

  console.log(chatData);

  useEffect(() => {
    setMessages(chatData?.data ? [...chatData?.data] : []);
  }, [chatData]);

  function reducer(state, action) {
    switch (action.type) {
      case "setIsRecording": {
        return {
          ...state,
          isRecording: action.payload,
        };
      }
      case "setIsTyping": {
        return {
          ...state,
          isTyping: action.payload,
        };
      }
      case "setIsSendingIMG": {
        return {
          ...state,
          isSendingIMG: action.payload,
        };
      }
      case "setCounter": {
        return {
          ...state,
          counter: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    isRecording: false,
    isTyping: false,
    isSendingIMG: false,
    counter: "00:01",
  });
  function handleTyping(e) {
    if (e.target.value !== "") {
      dispatch({ type: "setIsTyping", payload: true });
    } else {
      dispatch({ type: "setIsTyping", payload: false });
    }
  }
  function startCounter() {
    seconds.current = 1;
    minutes.current = 0;
    let secondsWithZero;
    let minutesdWithZero;
    counter.current = setInterval(() => {
      seconds.current++;
      if (seconds.current / 60 === 1) {
        seconds.current = 0;
        minutes.current++;
        if (minutes.current === 5) {
          stopRecording();
          resetCounter();
        }
      }
      if (seconds.current < 10) {
        secondsWithZero = "0" + seconds.current.toString();
      } else {
        secondsWithZero = seconds.current;
      }

      if (minutes.current < 10) {
        minutesdWithZero = "0" + minutes.current.toString();
      } else {
        minutesdWithZero = minutes.current;
      }
      dispatch({
        type: "setCounter",
        payload: minutesdWithZero + ":" + secondsWithZero,
      });
    }, 1000);
  }
  function resetCounter() {
    dispatch({ type: "setCounter", payload: "00:01" });
    dispatch({ type: "setIsRecording", payload: false });
    clearInterval(counter.current);
    seconds.current = 1;
    minutes.current = 0;
  }

  function startRecording(params) {
    dispatch({ type: "setIsRecording", payload: true });

    //recorder
    let device = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    device
      .then((streamobj) => {
        stream.current = streamobj;
        recorder.current = new MediaRecorder(streamobj);
        recorder.current.ondataavailable = (e) => {
          items.current.push(e.data);
        };
        recorder.current.start();
        startCounter();
      })
      .catch((Error) => {});
  }

  function stopRecording(params) {
    resetCounter();
    recorder.current.stop();
    recorder.current.stop();
    recorder.current.onstop = (e) => {
      let blob = new Blob(items.current, { type: "audio/webm" });
      items.current = [];
      let audioUrl = URL.createObjectURL(blob);

      let formData = new FormData();
      formData.append("src", blob, audioUrl);
      formData.append("message", null);
      formData.append("type", "voice");

      // setMessages((prev) => [...prev, { type: "voice", src: audioUrl }]);

      stream.current.getAudioTracks().forEach((track) => track.stop());
      stream.current = null;

      axios
        .post(process.env.REACT_APP_STUDENT_SEND_CHATS_API, formData)
        .catch((error) => {});
    };
  }

  function cancelRecording(params) {
    resetCounter();
    recorder.current.stop();
    recorder.current.onstop = (e) => {
      items.current = [];
      stream.current.getAudioTracks().forEach((track) => track.stop());
      stream.current = null;
    };
  }

  function sendTextOrImages() {
    if (textInput.current?.value !== "") {
      let msg = textInput.current?.value;
      // setMessages((prev) => [...prev, { type: "text", msg: msg }]);

      let finalObject = { message: msg, type: "text" };

      axios
        .post(process.env.REACT_APP_STUDENT_SEND_CHATS_API, finalObject)
        .then(() => {
          setUploadedImages([]);
        });

      textInput.current.value = "";
      textInput.current.focus();
    }
    if (uploadedImages?.length > 0) {
      let formData = new FormData();

      formData.append("message", null);
      formData.append("type", "img");

      uploadedImages.forEach((img, index) => {
        let imgFile = uploadedImages[index];
        formData.append(`src`, imgFile);

        axios
          .post(process.env.REACT_APP_STUDENT_SEND_CHATS_API, formData)
          .then(() => {
            setUploadedImages([]);
          });
      });
    }

    dispatch({ type: "setIsTyping", payload: false });
    dispatch({ type: "setIsSendingIMG", payload: false });
  }

  function handleAddImages(e) {
    setUploadedImages([...uploadedImages, ...Array.from(e.target.files)]);
    if (uploadedImages.length > 0) {
      //logic for not pushing an already exist image
      uploadedImages.forEach((newItem) => {
        let exist = false;
        uploadedImages.forEach((oldItem) => {
          if (oldItem.name === newItem.name) {
            exist = true;
          }
        });
        if (!exist) {
          setUploadedImages((curr) => [...curr, newItem]);
        }
      });
    } else {
      setUploadedImages((curr) => [...curr]);
    }

    if (uploadedImages?.length > 0) {
      dispatch({ type: "setIsSendingIMG", payload: true });
    } else {
      dispatch({ type: "setIsSendingIMG", payload: false });
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendTextOrImages();
    }
  };

  const onOpenChat = () => {
    setOpen(!open);
  };

  //TODO: REMOVE IT FROM LOCALSTORAGE
  const userId = JSON.parse(localStorage?.userData).student.id;

  useEffect(() => {
    // Initialize Pusher with your app credentials
    const pusher = new Pusher("5fbcf74173e8f5201b61", {
      cluster: "eu",
      // encrypted: true,
    });

    // Subscribe to the "chat" channel
    const channel = pusher.subscribe(`chatwith${userId}`);

    // Listen for new messages
    channel.bind("chatMessage", function (data) {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    // Clean up the Pusher subscription
    return () => {
      pusher.unsubscribe(`chatwith${userId}`);
    };
  }, []);

  console.log(chatData, chatDataErrors);

  return (
    <>
      <div className={style.studentChat}>
        <div className="chat">
          <div
            className="iconParent bg-secondary border-2 border-white"
            onClick={onOpenChat}
          >
            <div className="tooltipChat">Ask</div>
            <BsFillChatRightDotsFill />
          </div>

          <div className={open ? "chatOpen" : "chatClose"}>
            <div className="chatParent border-2">
              <ChatContent messages={messages} />
              {chatData?.is_active === 1 && (
                <ChatFooter
                  state={state}
                  stopRecording={stopRecording}
                  cancelRecording={cancelRecording}
                  handleAddImages={handleAddImages}
                  handleTyping={handleTyping}
                  textInput={textInput}
                  startRecording={startRecording}
                  sendTextOrImages={sendTextOrImages}
                  handleKeyDown={handleKeyDown}
                  uploadedImages={uploadedImages}
                  setUploadedImages={setUploadedImages}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
