import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { Loader } from "../../../../MainComponents";

function DeletedStudents() {
  // const table = useTable("deletedStudents");
  const [reFetch, setReFetch] = useState(false);

  const [
    getAllDeletedStudents,
    getAllDeletedStudentsErrors,
    getAllDeletedStudentsLoading,
  ] = useAxios(
    `${process.env.REACT_APP_ALL_DELETED_STUDENTS_API}`,
    "GET",
    "GET",
    reFetch
  );
  const table = useTable(
    "deletedStudents",
    false,
    "",
    getAllDeletedStudents?.data,
    reFetch,
    "",
    true,
    getAllDeletedStudents?.data,
    reFetch,
    setReFetch
  );

  if (getAllDeletedStudentsLoading) return <Loader />;
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Deleted Students | Nawar Academy"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">
        Deleted Students {getAllDeletedStudents?.data?.length}
      </h2>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default DeletedStudents;
