//!you may need to change record.id to record.key

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../../../MainComponents/Hooks/useAxios.jsx";

function ChatSettings() {
  const [values, setValues] = useState(null);

  const [submitChatSettings, setSubmitChatSettings] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const AuthState = useAuthState();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched", values });
  const watchActive = watch("is_active");
  let api = process.env.REACT_APP_ADMIN_UPDATE_CHAT_SETTINGS_API;
  const [updateChatSettingsSuccess, updateChatSettingsErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      submitChatSettings.flag,
      submitChatSettings.dependency,
      submitChatSettings.data,
      true
    );

  const [chatSettingsInfo, chatSettingsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_GET_CHAT_SETTINGS_API,
    "GET",
    "GET",
    updateChatSettingsSuccess
  );

  const onSubmit = (data) => {
    setSubmitChatSettings({
      flag: "updateChatSettingsRequest",
      dependency: !submitChatSettings.dependency,
      data: {
        is_active: +data.is_active,
        message: +data.is_active === 1 ? null : data.message,
      },
    });
  };

  useEffect(() => {
    if (chatSettingsInfo) {
      let temp = chatSettingsInfo.data;
      setValues({
        ...temp,
        visibility: temp?.visibility === 1 ? true : false,
      });
    }
  }, [chatSettingsInfo]);

  console.log(watchActive);

  return (
    <section className="dark:bg-dark dark:text-light w-full overflow-y-auto px-10  sm:px-4">
      {(AuthState?.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState?.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("code_add")
        )) && (
        <div className="form-codes-container my-10 flex items-center justify-center">
          <div className="flex h-fit  w-1/2 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl   p-10 shadow-lg shadow-red-200/30 ">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="shadow-l flex h-fit w-full  flex-col items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-blue/0 md:w-full "
            >
              <div className=" flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <label className="w-full truncate" htmlFor="message">
                  Message
                </label>
                <textarea
                  name=""
                  id=""
                  disabled={watchActive}
                  className="signin-inputs   w-full  resize-none !h-[100px]"
                  {...register("message", {
                    required: false,
                  })}
                ></textarea>

                {errors.message && (
                  <p className="text-xl text-red-500 ">
                    {errors.message.type === "required" &&
                      "This field is required."}
                  </p>
                )}
                {
                  //!-------server errors -----

                  updateChatSettingsErrors &&
                    updateChatSettingsErrors?.response?.data?.errors
                      ?.message && (
                      <p className="w-full text-end text-xl text-red-500  ">
                        {
                          updateChatSettingsErrors?.response?.data?.errors
                            ?.message[0]
                        }
                      </p>
                    )
                }
              </div>

              {/** length of code field */}

              <div className=" visibility flex w-full flex-col items-center justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-center gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="is_active"
                      name="is_active"
                      {...register("is_active", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="is_active"
                    >
                      Active
                    </label>
                  </div>
                </div>

                {errors.is_active && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.is_active.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  updateChatSettingsErrors &&
                    updateChatSettingsErrors?.response?.data?.errors
                      ?.is_active && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {
                          updateChatSettingsErrors?.response?.data?.errors
                            ?.is_active[0]
                        }
                      </p>
                    )
                }
              </div>

              {/** submit */}
              <button
                disabled={
                  !isValid || (submitLoading && submitChatSettings.flag)
                }
                className=" submit mt-6 w-full "
                type="submit"
              >
                {submitLoading && submitChatSettings.flag ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Update Chat Settings"
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </section>
  );
}

export default ChatSettings;
