import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";

function CenterGroup({ tableFor }) {
  const { groupId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const [totalPages, setTotalPages] = useState();

  const api =
    tableFor === "attendanceGroupShowSessions"
      ? process.env.REACT_APP_GET_ALL_ATTENDANCE_SESSIONS_API
      : tableFor === "quizzesGroupShowSessions"
        ? process.env.REACT_APP_GET_ALL_QUIZZES_SESSIONS_API
        : process.env.REACT_APP_GET_ALL_EXAMS_SESSIONS_API;

  const [allGroups, allGroupsErrors, allGroupsLoading] = useAxios(
    `${api}/${groupId}`,
    "GET",
    "GET",
    ""
  );

  const recordsPerPage = 15;
  const alwaysVisibleKeys = 3;

  useEffect(() => {
    if (allGroups?.data) {
      const allKeys = Object.keys(allGroups.data[0]);

      // Determine the keys for pagination (excluding the first alwaysVisibleKeys)
      const keysToPaginate = allKeys.slice(alwaysVisibleKeys);

      // Calculate the index range for the current page
      const startIndex = (currentPage - 1) * recordsPerPage;
      const endIndex = startIndex + recordsPerPage;
      const currentKeys = keysToPaginate.slice(startIndex, endIndex);

      // Filter data to include only the current keys
      const filteredData = allGroups.data.map((record) =>
        allKeys
          .slice(0, alwaysVisibleKeys)
          .concat(currentKeys)
          .reduce((acc, key) => {
            acc[key] = record[key];
            return acc;
          }, {})
      );

      setDisplayedData(filteredData);
    }
  }, [allGroups, currentPage]);

  useEffect(() => {
    if (allGroups?.data) {
      setTotalPages(
        Math.ceil(Object.keys(allGroups?.data[0])?.length / recordsPerPage)
      );
    }
  }, [allGroups?.data]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const table = useTable(
    tableFor,
    "",
    "",
    displayedData,
    "",
    "",
    true,
    displayedData
  );

  console.log(displayedData[0], allGroups?.data[0]);
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Groups | Nawar Academy"></HelmetTags>

      {/**
       * //!---------Table-----
       */}
      <div className="mb-12 top-btns flex w-full justify-start gap-10 flex-wrap">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            className={`w-20 h-20 rounded-xl border-2 border-secondary px-2 py-1 font-semibold transition-all duration-300 ${
              currentPage === i + 1
                ? "bg-light text-secondary"
                : "bg-secondary text-light hover:bg-light hover:text-secondary"
            } active:scale-90`}
          >
            {i + 1}
          </button>
        ))}
      </div>
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default CenterGroup;
