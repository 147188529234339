import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";

function AdminLectures() {
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [stage, setStage] = useState(null);
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  console.log(
    allLecturesData?.data
      ?.filter((lect) => +lect.is_course === 0)
      ?.filter((lect) => +lect.stage === stage)
  );

  return (
    <section
      className={`h-full  w-full  px-20 py-20 md:px-5 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Lectures</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <div className="flex w-full gap-10 md:flex-col md:justify-evenly">
            <button
              onClick={() => navigate("/admin/lectures/add-lecture")}
              className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add lecture
            </button>
            <button
              onClick={() => navigate("/admin/lectures/add-chapter")}
              className="mr-4 rounded-xl border-2 border-secondary order-1 bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add a chapter
            </button>
          </div>
        )}
      </div>
      <div className="mb-32 flex w-full gap-10 md:justify-evenly">
        <button
          onClick={() => setStage(1)}
          className={`mr-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 ${
            stage === 1 && "bg-secondary !text-light"
          }`}
        >
          Stage 1
        </button>
        <button
          onClick={() => setStage(2)}
          className={`mr-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 ${
            stage === 2 && "bg-secondary !text-light"
          }`}
        >
          Stage 2
        </button>
        <button
          onClick={() => setStage(3)}
          className={`mr-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 ${
            stage === 3 && "bg-secondary !text-light"
          }`}
        >
          Stage 3
        </button>
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit mb-[50px]">
          {allLecturesData?.data?.length === 0 ? (
            <p>No lectures</p>
          ) : (
            allLecturesData?.data
              ?.filter((lect) => +lect.is_course === 0)
              ?.filter((lect) => +lect.stage === stage)
              .map((Card) => {
                if (Card.is_chapter) {
                  return (
                    <SingleChapter
                      reFetch={reFetch}
                      setReFetch={setReFetch}
                      admin
                      chapter={Card}
                      key={Card.key}
                    />
                  );
                } else {
                  return (
                    <SingleCourse
                      reFetch={reFetch}
                      setReFetch={setReFetch}
                      admin
                      lecture={Card}
                      key={Card.key}
                    />
                  );
                }
              })
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
