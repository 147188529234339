import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Title,
  Filler,
} from "chart.js";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";

ChartJS.register(
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Title,
  Filler
);

function Analytics() {
  const [stageCount, setStageCount] = useState([]);
  const [analyticsData, analyticsDataErrors, analyticsDataLoading] = useAxios(
    process.env.REACT_APP_ADMIN_ANALYTICS_API,
    "GET",
    "GET",
    "",
    ""
  );

  const DoughnutData = {
    labels: ["Stage 1", "Stage 2", "Stage 3"],
    datasets: [
      {
        label: "Stages",
        data: stageCount,
        backgroundColor: [
          "#4285f4",
          "rgb(110, 231, 183)",
          "rgba(189, 19, 236)",
        ],
        borderColor: [
          "rgba(3, 65, 221, 1)",
          "rgb(110, 231, 183,1)",
          "rgba(189, 19, 236, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    setStageCount((prev) =>
      analyticsData?.data.stagecount.map((count) => count.count)
    );
  }, [analyticsData]);
  return (
    <section className="dark:bg-dark dark:text-light h-full w-full overflow-y-auto px-10 py-10 sm:px-4">
      <HelmetTags title="Analytics | Nawar Academy"></HelmetTags>{" "}
      <h2 className="w-full text-4xl font-bold md:text-centers">Analytics</h2>
      <div
        //!container
        className="lef-right-container flex  w-full items-center justify-center gap-12 lg:flex-col lg:items-center lg:justify-start lg:gap-20 "
      >
        <div
          //!left
          className="admin-dash-left flex  w-[400px] flex-col items-start justify-start gap-8 lg:w-full lg:items-center "
        >
          <div className="line-chart  flex max-h-[510px] w-full flex-col items-start justify-start gap-4  lg:items-center xs:w-[80%]">
            <Doughnut data={DoughnutData} height={90}></Doughnut>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Analytics;
