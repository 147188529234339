const RightQuestionsSidebar = ({ state, dispatch }) => {
  return (
    <div
      className={` right-questions-sidebar-collapse overflow-y-auto  px-[20px] transition-all duration-300 ease-in-out md:w-full  `}
    >
      <div className={`myfixed mytoggle-fixed h-fit w-full  `}>
        <div className="myquestions-container-relative h-fit w-full mt-12">
          <div className="myquestions-wrapper mytoggle-QuestionsWrapper h-fit w-full">
            <div className="flex items-center gap-10 flex-wrap h-fit  w-full">
              {state.questions.map((question, i) => (
                <span
                  key={i}
                  className={`${
                    question.is_selected
                      ? "selected-popup-question"
                      : "myquestion"
                  }`}
                  onClick={() =>
                    dispatch({ type: "setCurrentQuestionIndex", payload: i })
                  }
                >
                  {i + 1}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightQuestionsSidebar;
