import { useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useEffect, useState } from "react";

function CenterViewTable({ tableFor }) {
  const AuthState = useAuthState();
  const { lectureID } = useParams();

  const [data, setData] = useState();

  const [allGroupsData, allGroupsErrors, allGroupsloading] = useAxios(
    process.env.REACT_APP_ALL_GROUPS_API,
    "GET",
    "GET",
    "",
    true
  );

  useEffect(() => {
    if (allGroupsData) {
      setData(allGroupsData.data.filter((group) => +group.is_online === 0));
    }
  }, [allGroupsData]);

  console.log(data);

  const table = useTable(
    tableFor,
    "",
    "",
    data,
    "",
    "",
    true,
    data,
    "",
    "",
    true
  );
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Groups | Nawar Academy"></HelmetTags>

      {/**
       * //!---------Table-----
       */}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("lecture_view")
        )) && <div className="h-fit  w-full">{table}</div>}
    </section>
  );
}

export default CenterViewTable;
