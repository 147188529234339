import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useState } from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

function Center() {
  const [academiclevel, setAcademiclevel] = useState(null);

  const [allGroups, allGroupsErrors, allGroupsLoading] = useAxios(
    `${process.env.REACT_APP_ALL_GROUPS_API}`,
    "GET",
    "GET",
    ""
  );
  const table = useTable(
    "studentFilter",
    "",
    "",
    academiclevel,
    "",
    academiclevel,
    true,
    allGroups?.data.filter((data) => +data.stage === +academiclevel)
  );

  if (allGroupsLoading) {
    return <Loader />;
  }
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Center Students | Nawar Academy"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          <button
            onClick={() => setAcademiclevel(1)}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              academiclevel !== 1
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Stage 1
          </button>
          <button
            onClick={() => setAcademiclevel(2)}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              academiclevel !== 2
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Stage 2
          </button>
          <button
            onClick={() => setAcademiclevel(3)}
            className={`md:w-full rounded-xl border-2 border-secondary p-4 font-semibold transition-all duration-300 hover:bg-light hover:text-secondary  active:scale-90 ${
              academiclevel !== 3
                ? "bg-secondary text-white"
                : "bg-white text-secondary"
            }`}
          >
            Stage 3
          </button>
        </div>
      </div>
      {academiclevel && <div className="h-fit  w-full">{table}</div>}
    </section>
  );
}

export default Center;
